import React, { Component } from "react";
import jwtDecode from "jwt-decode";
import { getToken } from "../../auth-helpers";
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Line, Pie, Doughnut, Bar, Radar } from 'react-chartjs-2';
import 'datatables.net-dt/js/dataTables.dataTables.min.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
const $ = require('jquery');
$.DataTable = require('datatables.net');
const campañamanager = require('../../ServerFunctions/campaignmanager');
const contactmanager = require('../../ServerFunctions/contactmanager');
const templatemanager = require('../../ServerFunctions/templatemanager.js');
const conectormanager = require('../../ServerFunctions/conectormanager.js');
const { v4: uuidv4 } = require('uuid');


export default class ReportesTemplates extends Component {
constructor(props){
    super(props);
    this.state={
        templates:[],

        visible:'none',
        fdate:'block',
        fcampaign:'none',
        visibled:'none',
        value:'date',
        conectores_list_value:[],
        conectores_list_name:[],
        conectores_list_price:[],
        conectores_list_pais:[],
        conectores_list_value_a:[],
        conectores_list_name_a:[],
        conectores_list_price_a:[],
        conectors:[],
        title:'Messages sent in the month',
        cnt_campaña_env:0,
        cnt_campaña_err:0,
        cnt_agente_env:0,
        cnt_agente_err:0,
        cnt_api_error:0,
        cnt_api_envio:0,
        cost_valor:0,
        con_name:"",
        contactos:[],
        agentes:[],
        buscando:false,
        msgbusqueda:"Buscando....",
        isActive:true

          }    
    this.Load= this.Load.bind(this);
    this.handleExport=this.handleExport.bind(this)
}

componentDidMount(){
    var authToken = getToken();
    var thisa= this;
    var usr;

    if(authToken){
        var user = jwtDecode(authToken);
        usr={
            companyName:user['custom:companyName'],
            email:user['email'],
            role:user['custom:role']
        };
        this.setState({
            user:usr
        })
    }
    conectormanager.ListConector(authToken,function(conectors) {
      thisa.setState({
          conectors,
      })
  });
    templatemanager.ListTemplate(authToken,function(templates) {
          thisa.setState({
            templates,
            isActive:false
        })
        $('#templatesPC').DataTable();

    });
}

Load(template,lastkey){
    this.setState({
        visible:'block',
        isActive:true
    })
    var contactos=this.state.contactos
    if(lastkey==undefined){
      this.setState({
        contactos:[],
        agentes:[],
        cnt_campaña_err:0,
              cnt_campaña_env:0,
              cnt_api_error:0,
              cnt_api_envio:0,
              cnt_campaña_err:0,
              cnt_campaña_env:0,
              cnt_api_error:0,
              cnt_api_envio:0,
              contactos:[],
              agentes:[],
              isActive:false
      })
      contactos=[]
    }
    var authToken = getToken();
    var thisa= this;
    var cnt_envio=0;
    var cnt_error=0;
    var cnt_envioa=0;
    var cnt_errora=0;
    var cnt_api_envio=0;
    var cnt_api_error=0;

    contactmanager.ObtenerContactosTemplate(authToken,template.template_id,lastkey,function(bcontactos){
      if(bcontactos){
        if(bcontactos.Items==undefined || bcontactos.Items==null){
          console.log("FIN")
          contactos=contactos.concat(bcontactos)
          console.log(bcontactos.length)
          console.log(contactos.length)

          thisa.setState({
            contactos:contactos
          })
          for(var i=0;i<contactos.length;i++){
          if(contactos[i].estado_mensaje==null || contactos[i].estado_mensaje==undefined){
            contactos[i].estado_mensaje=contactos[i].estado
          }
          if(contactos[i].numero_contacto==null || contactos[i].numero_contacto==undefined){
            contactos[i].numero_contacto=contactos[i].contacto
          }
          if(contactos[i].codigo_error==null || contactos[i].codigo_error==undefined){
            contactos[i].codigo_error=contactos[i].codigo
          }
          if(contactos[i].mensaje_error==null || contactos[i].mensaje_error==undefined){
            contactos[i].mensaje_error=contactos[i].mensaje
          }
          if(contactos[i].detalle_error==null || contactos[i].detalle_error==undefined){
            contactos[i].detalle_error=contactos[i].detalle
          }
          
        if(contactos[i].medio_envio==null || contactos[i].medio_envio==undefined){
          contactos[i].medio_envio=contactos[i].envio
        }

                    if((contactos[i].estado_mensaje=="enviado" || contactos[i].estado=="enviado") && (contactos[i].medio_envio=="campaña" || contactos[i].envio=="campaña")){
                    cnt_envio=cnt_envio+1;
                }else if((contactos[i].estado_mensaje=="error" || contactos[i].estado=="error") && (contactos[i].medio_envio=="campaña" || contactos[i].envio=="campaña")){
                    cnt_error=cnt_error+1;
                }else if((contactos[i].estado_mensaje=="enviado" || contactos[i].estado=="enviado") && (contactos[i].medio_envio=="api" || contactos[i].envio=="api")){
                    cnt_api_envio=cnt_api_envio+1;
                }else if((contactos[i].estado_mensaje=="error" || contactos[i].estado=="error") && (contactos[i].medio_envio=="api" || contactos[i].envio=="api")){
                  cnt_api_error=cnt_api_error+1;
                }

           }
           if(contactos[0]!=undefined){
            var costo= thisa.state.conectors.filter(conector=>conector.conector_id==contactos[0].conector_id)[0]
            thisa.setState({
               cnt_err:cnt_error,
               cnt_env:cnt_envio,
               cnt_campaña_env:cnt_envio,
               cnt_campaña_err:cnt_error,
               cnt_api_error:cnt_api_error,
               cnt_api_envio:cnt_api_envio,
               contactos:contactos,
               cost_valor:costo.costo_hsm,
               con_name:costo.nombre,
               isActive:false
            })
           }
           
        }else{
          console.log("NOSE")

          contactos=contactos.concat(bcontactos.Items)
          thisa.setState({
            contactos:contactos
          })
          console.log(bcontactos.Items.length)
          console.log(contactos.length)
          console.log(thisa.state.contactos.length)
          console.log(contactos.LastEvaluatedKey)
          thisa.Load(template,bcontactos.LastEvaluatedKey)

        }
        }else{
          thisa.setState({
            cnt_campaña_err:0,
              cnt_campaña_env:0,
              cnt_api_error:0,
              cnt_api_envio:0,
              contactos:[],
              isActive:false
        })
        }
    })

    contactmanager.ObtenerContactosTemplateAgente(authToken,template.template_id,lastkey,function(contactos){
if(contactos){
  for(var i=0;i<contactos.length;i++){
    if(contactos[i].estado_mensaje==null || contactos[i].estado_mensaje==undefined){
      contactos[i].estado_mensaje=contactos[i].estado
    }
    if(contactos[i].numero_contacto==null || contactos[i].numero_contacto==undefined){
      contactos[i].numero_contacto=contactos[i].contacto
    }
    if(contactos[i].codigo_error==null || contactos[i].codigo_error==undefined){
      contactos[i].codigo_error=contactos[i].codigo
    }
    if(contactos[i].mensaje_error==null || contactos[i].mensaje_error==undefined){
      contactos[i].mensaje_error=contactos[i].mensaje
    }
    if(contactos[i].detalle_error==null || contactos[i].detalle_error==undefined){
      contactos[i].detalle_error=contactos[i].detalle
    }
    
  if(contactos[i].medio_envio==null || contactos[i].medio_envio==undefined){
    contactos[i].medio_envio=contactos[i].envio
  }

                if(contactos[i].estado_mensaje=="enviado" || contactos[i].estado=="enviado"){
                    cnt_envioa=cnt_envioa+1;
                }else{
                    cnt_errora=cnt_errora+1;
                }

           }
           var costo= thisa.state.conectors.filter(conector=>conector.conector_id==contactos[0].conector_id)
           
           thisa.setState({
                cnt_agente:contactos.length,
                cnt_agente_env:cnt_envioa,
                cnt_agente_err:cnt_errora,
                agentes:contactos,
                cost_valor:costo[0].costo_hsm,
               con_name:costo[0].nombre,
              isActive:false
           })
           
        }else{
          thisa.setState({
            cnt_agente:0,
            cnt_agente_env:0,
            cnt_agente_err:0,
            agentes:[],
          isActive:false

         })
        }
    })
}

handleExport(event){
  event.preventDefault();

  var items=this.state.contactos;
  var agents=this.state.agentes;
  for(let i=0;i<agents.length;i++){
    items.push(agents[i])
  }
  var filename= uuidv4();
  var headers={
    user_id:'Id',
    numero_contacto:'Contacto',
    medio_envio:'TipoEnvio',
    fecha_hora:'FechaInteraccion',
    conector_name:'Conector',
    template_name:'Template',
    estado_mensaje:"Estado",
    codigo_error:"Codigo",
    mensaje_error:"Mensaje",
    detalle_error:"Detalle",
  }
var agentes=false;
var campana=false;
  if(agents.length>0){
    headers.agent_id="AgenteId"
    headers.agente="Agente"
    agentes=true
  }
  if(items.length>0){
    headers.usuario="Usuario"
    campana=true
  }
  const jsonObject = JSON.stringify(items);
const csv = this.convertToCSV(jsonObject,headers,agentes,campana);
  const exportName = filename + ".csv" || "export.csv";
const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
  navigator.msSaveBlob(blob, exportName);
  } else {
      console.log("AQUI2")
      const link = document.createElement("a");
      if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      }
}
}
convertToCSV(objArray,headers,agentes,campana) {
const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
let str = "Id,Contacto,TipoEnvio,FechaEnvio,Conector,Template,EstadoEnvio,CodigoError,MensajeError,DetalleError"
if(agentes){
str=str+",AgenteId,Agente"
}
if(campana){
str=str+",Usuario"
}
str=str+ "\r\n";
for (let i = 0; i < array.length; i++) {
    let line = "";
    for (let index in headers) {
        if (line != ""){
            line += ",";
        }
        var text =array[i][index];
        var tipo=array[i]["medio_envio"]
        if(headers[index]=="FechaEnvio"){
          text= (new Date(text)).toLocaleString()
        }
        if(headers[index]=="Usuario"){
          if(tipo=="campaña"){
            text=array[i]["agente"]
          }
        }
        if(headers[index]=="Agente"){
          if(tipo=="campaña"){
            text="-"
          }
        }
        if(text===undefined){
          text="-"
      }else if(text===""){
          text="-"
      }
        line += text;
    }
    str += line + "\r\n";
}

return str;
}
    render() {
      var values=[]
      var prices=[]
      for( let i=0;i<this.state.conectores_list_value.length;i++){
        values.push(parseInt(this.state.conectores_list_value[i])+parseInt(this.state.conectores_list_value_a[i]))
        prices.push(parseFloat(this.state.conectores_list_price[i])+parseFloat(this.state.conectores_list_price_a[i]))
      }
      var template=this.state.templates;
        const CheckBox = ({template}) => (
            <input
              id={template.template_id}
              onChange={(e) => {
                  e.preventDefault()
                this.setState({
                    checkedId:template.template_id,
                    template:template
                })
                this.Load(template)
              }}
              checked={this.state.checkedId === template.template_id}
              type="checkbox"
            />
          )
          const genPieData=()=>{
            return {
              datasets: [
                {
                  data: [parseInt(this.state.cnt_campaña_env)+parseInt(this.state.cnt_agente_env)+parseInt(this.state.cnt_api_envio),parseInt(this.state.cnt_campaña_err)+parseInt(this.state.cnt_agente_err)+parseInt(this.state.cnt_api_error)],
                  backgroundColor: [
                    '#77dd77',
                    '#ff6961',
                  ],
                  label: 'Campaña',
                },
              ],
              labels: ['Enviados', 'Error'],
            };
          };
          const genPieDatas=()=>{
            return {
              datasets: [
                {
                  data: [this.state.cnt_agente,parseInt(this.state.cnt_campaña_env)+parseInt(this.state.cnt_campaña_err), parseInt(this.state.cnt_api_envio)+parseInt(this.state.cnt_api_error)],
                  backgroundColor: [
                    '#fdfd69',
                    '#77dd77',
                    '#80CEE1'
                  ],
                  label: 'Campaña',
                },
              ],
              labels: ['Agentes', 'Campaña','API'],
            };
          };

          const genPieDatacam=()=>{
            return {
              datasets: [
                {
                  data: [this.state.cnt_campaña_env,this.state.cnt_campaña_err],
                  backgroundColor: [
                    '#77dd77',
                    '#ff6961',
                  ],
                  label: 'Campaña',
                },
              ],
              labels: ['Enviados', 'Error'],
            };
          };
          const genPieDatasagen=()=>{
            return {
              datasets: [
                {
                  data: [this.state.cnt_agente_env,this.state.cnt_agente_err],
                  backgroundColor: [
                    '#77dd77',
                    '#ff6961',
                  ],
                  label: 'Campaña',
                },
              ],
              labels: ['Enviados', 'Error'],
            };
          };
          const genPieDatasapi=()=>{
            return {
              datasets: [
                {
                  data: [this.state.cnt_api_envio,this.state.cnt_api_error],
                  backgroundColor: [
                    '#77dd77',
                    '#ff6961',
                  ],
                  label: 'API',
                },
              ],
              labels: ['Enviados', 'Error'],
            };
          };
         
  var isActive=this.state.isActive;
        return (
        <>       
         <LoadingOverlay
            active={isActive}
            spinner
            styles={{
              wrapper: {
                width: '100%',
                height: '100%',
                overflow: isActive ? 'hidden' : '',
              },
            }}
            text="Cargando ..."
          >
              <div id="repcampaign">
            <br/>
            <h2>Reportes por Template</h2>
            <div id="cognitousers" className="table-responsive" style={{maxHeight:"500px"}}>
            <table  id="templatesPC" className="display" style={{ width: '100%', float: 'left' }}>
                <thead>
                    <tr>
                    <th></th>
                    <th>Nombre</th>
                    <th>Id</th>
                    <th>País</th>
                    </tr>
                </thead>
            <tbody>
                {
                    template?(
                        <>
                        {template.map((item, i) => {
                        if(item.verificacion=="true"){
                            return (
                              <tr key={i}>
                                  <td>
                                  <CheckBox template={item} />
                                  </td>
                                  <td>{item.template_name}</td>
                                  <td>{item.template_id}</td>
                                  <td>
                                        {
                                            item.conector.codigo=="593"?("EC"):(
                                            <>
                                                {
                                                   item.conector.codigo=="51"?("PE"):("COL")
                                                }
                                            </>)
                                        }
                                    </td>
                              </tr>
                          );
                          }
                        })}        
                    </>
                    ):(
                        <>
                        </>
                    )
                }
            </tbody>
          </table>
            </div>
            <br/>
            <div style={{display:(this.state.visible)}} >
            <div className="row">
                <div className="col-sm-3">
                <button  className="paddingbutton btn btn-primary btn-block" 
                onClick={this.handleExport}>Exportar Lista de contactos</button>
                </div>
            </div>
            <br/>
          <Row>
            <Col xl={3} lg={4} md={4}>
            <Card>
                <CardHeader>Mensajes Enviados {this.state.con_name} :$ {parseFloat(this.state.cost_valor)*(parseInt(this.state.cnt_env)+parseInt(this.state.cnt_agente_env)+parseInt(this.state.cnt_api_envio))}</CardHeader>
                <CardBody>
                <Pie data={genPieData} />
                </CardBody>
            </Card>
            </Col>

            <Col xl={3} lg={4} md={4}>
            <Card>
                <CardHeader>Medio de Envio</CardHeader>
                <CardBody>
                <Pie data={genPieDatas} />
                </CardBody>
            </Card>
            </Col>
            <Col xl={3} lg={4} md={4}>
            <Card>
                <CardHeader>Mensajes enviados por campaña</CardHeader>
                <CardBody>
                <Pie data={genPieDatacam} />
                </CardBody>
            </Card>
            </Col>

            <Col xl={3} lg={4} md={4}>
            <Card>
                <CardHeader>Mensajes Enviados por agentes</CardHeader>
                <CardBody>
                <Pie data={genPieDatasagen} />
                </CardBody>
            </Card>
            </Col>

            <Col xl={3} lg={4} md={4}>
            <Card>
                <CardHeader>Mensajes Enviados por api</CardHeader>
                <CardBody>
                <Pie data={genPieDatasapi} />
                </CardBody>
            </Card>
            </Col>
        </Row>
          </div>       
        </div>
          </LoadingOverlay>
      
         </>
        );
    }
}