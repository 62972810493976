//Configure Environment
import axios from 'axios';
import { getUser } from '../auth-helpers';
const { v4: uuidv4 } = require('uuid');

//desarrollo
//const url_campaña="http://localhost:3030/"

//desarrollo
const url_campaña="https://dq7klthzn9.execute-api.us-east-2.amazonaws.com/dev/"

//producción
//const url_campaña="https://cj3pqyeckb.execute-api.us-east-2.amazonaws.com/prod/";


export function ListSegmentos(token,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.get( 
        url_campaña+`segmentos`,
      config
    ).then(res=>{
         callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function InsertarBase(token,archivo,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.post( 
        url_campaña+"insertar/base",
        archivo,
        config
    ).then(res=>{
        callback(res.data,null)
    }).catch(err=>{
        callback(null,err)
    });
}

export function InsertarSegmento(token,archivo,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.post( 
        url_campaña+"segmento",
        archivo,
        config
    ).then(res=>{
        callback(res.data,null)
    }).catch(err=>{
        callback(null,err)
    });
}

export function EliminarSegmento(token,conector_id,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
   
    axios.delete( 
        url_campaña+"segmento/"+conector_id,
        config
    ).then(resp=>{
        resp.data.mensaje='Base eliminada exitosamente'
        callback(resp.data)
    }).catch(err=>{
        callback(null)
    });
    

}