import React from "react";
import { useState } from "react";
import {setRefresh, setToken } from  "../auth-helpers"
import LoadingOverlay from 'react-loading-overlay-ts';
const usermanager = require('../ServerFunctions/usermanager');
const Login=()=> {

    const [email,setEmail]=useState("");
    const [password,setPassword]=useState("");
    const [companyname,setCompanyName]=useState("");
    const [saber,setSaber]=useState("");
    const [tipo,setTipo]=useState("password");
    const [disabled,setDisabled]=useState(false);
    const [isActive, setIsActive] = useState(false);
    const onSubmit = event=>{
        event.preventDefault();
        setSaber("")
        if(email===""||password===""||companyname===""){
            setSaber("Campos vacios")
            setDisabled(false)
            setIsActive(false);

        }else{
            setDisabled(true)
            setIsActive(true);
            var user={
                email:email,
                userName:email,
                password:password,
                companyName:companyname
            }
            usermanager.Login(user, function(users) {
                if(users.message==='Logued'){
                    setToken(users.token);
                    setRefresh(users.refresh)
                    setIsActive(false);
                    window.location.href='/'
                }else if(users.message==="true"){
                    setIsActive(false);
                    window.localStorage.setItem('changepassword',user.email+'/'+user.companyName);                        
                    setSaber("Actualizar contraseña")
                    window.location.href="/"
                }else{
            setIsActive(false);
            alert(
                'Hubo un problema al iniciar su sesión.\n\nVerifique su dirección de correo electrónico, contraseña y organización e inténtelo de nuevo.'
            );
            setSaber(users.message)
                    setDisabled(false)
                }
            });
        }
    };

    const handleRecuperar = (event) => {
        event.preventDefault();
        window.localStorage.setItem('forgotPassword', true);
        window.location.href = '/';
      };

    return (
            <LoadingOverlay
      active={isActive}
      spinner
      styles={{
        wrapper: {
          width: '100%',
          height: '100%',
          overflow: isActive ? 'hidden' : '',
        },
      }}
      text="Cargando ..."
    >
        <div className="auth-wrapper">
              <div className="auth-inner">
            
            <form onSubmit={onSubmit.bind(this)}>
                <h3>Iniciar Sesión</h3>

                <label>Correo electrónico</label>
                <div className="form-group">
                <div className="input-group input-group-lg ">
              <span class="input-group-text txtemail" id="basic-addon1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-envelope-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z" />
                </svg>
              </span>
              <input
                type="email"
                className="form-control txtemail"
                placeholder="Dirección de correo electrónico"
                disabled={(disabled)? "disabled" : ""} 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
                </div>
                </div>
                <div className="form-group">
                    <label>Contraseña</label>
                    <div className="input-group input-group-lg ">
                  <span class="input-group-text txtPsw" id="basic-addon1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-file-lock2-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7 6a1 1 0 0 1 2 0v1H7V6z" />
                      <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-2 6v1.076c.54.166 1 .597 1 1.224v2.4c0 .816-.781 1.3-1.5 1.3h-3c-.719 0-1.5-.484-1.5-1.3V8.3c0-.627.46-1.058 1-1.224V6a2 2 0 1 1 4 0z" />
                    </svg>
                  </span>
                  <input
                    type={tipo}
                    className="form-control txtpassw"
                    placeholder="Contraseña"
                    disabled={(disabled)? "disabled" : ""}
                    value={password}
                    autocomplete="new-password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  
                </div>
                <br/>
                <div>
                <input type="checkbox" id="mostrar_contrasena" title="clic para mostrar contraseña" 
                onChange={(e) => {
                  if(e.target.checked){
                    setTipo("text")
                  }else{
                    setTipo("password")
                  }
                }}/>
              Mostrar Contraseña</div>
                </div>

                <div className="form-group">
                    <label>Organización</label>
                    <div className="input-group input-group-lg ">
              <span class="input-group-text txtorga" id="basic-addon1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-building"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
                  />
                  <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z" />
                </svg>
              </span>
              <input
                type="text"
                className="form-control txtorga"
                placeholder="Ingrese su organización"
                disabled={(disabled)? "disabled" : ""}
                value={companyname}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
                </div>


                <button  disabled={(disabled)? "disabled" : ""} onClick={onSubmit.bind(this)} className="btn btn-primary btn-block">Ingresar</button>
                <div>
                <label>{saber}</label>
                </div>
            </form>
            <div style={{ textAlign: 'center', fontSize: '12px' }}>
            <a href="/" onClick={handleRecuperar.bind(this)}>
              ¿Olvidó su contraseña?
            </a>
          </div>
            </div>
            </div>
    </LoadingOverlay>
            

        );
};
export default Login;