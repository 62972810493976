import React, { Component } from "react";
import jwtDecode from "jwt-decode";
import { getToken } from "../../auth-helpers";
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Line, Pie, Doughnut, Bar, Radar } from 'react-chartjs-2';
const campañamanager = require('../../ServerFunctions/campaignmanager');
const contactmanager = require('../../ServerFunctions/contactmanager');
const conectormanager = require('../../ServerFunctions/conectormanager.js');
const { v4: uuidv4 } = require('uuid');


export default class Reportesfechas extends Component {
constructor(props){
    super(props);
    this.state={
        fdate:'block',
        fcampaign:'none',
        visibled:'none',
        value:'date',
        conectores_list_value:[],
        conectores_list_name:[],
        conectores_list_price:[],
        conectores_list_pais:[],
        conectores_list_value_a:[],
        conectores_list_name_a:[],
        conectores_list_price_a:[],
        conectors:[],
        title:'Messages sent in the month',
        cnt_campaña_env:0,
        cnt_campaña_err:0,
        cnt_agente_env:0,
        cnt_agente_err:0,
        cost_valor:0,
        con_name:"",
        contactos:[],
        agentes:[],
        buscando:false,
        msgbusqueda:"Buscando....",
        isActive:true.valueOf,
        StartDate:undefined,
        EndDate:undefined,
        REndDate:undefined

          }    
    this.handleExport=this.handleExport.bind(this)
    this.handleSearch=this.handleSearch.bind(this)
}

componentDidMount(){
    var authToken = getToken();
    var thisa= this;
    var usr;
    if(authToken){
        var user = jwtDecode(authToken);
        usr={
            companyName:user['custom:companyName'],
            email:user['email'],
            appid:user['custom:appid']
        };
    }
    var today=new Date()
    var year=today.getFullYear()
    var mes=parseInt(today.getMonth())+1
    var dia=today.getDate()
    var Rdia=parseInt(today.getDate())+1
    if(parseInt(dia)<10){
      dia="0"+dia
    }
    if(parseInt(Rdia)<10){
      Rdia="0"+Rdia
    }
    if(parseInt(mes)<10){
      mes="0"+mes
    }
    console.log(dia)
    console.log(mes)
    console.log(year)
    this.setState({
      StartDate:year+"-"+mes+"-01",
      EndDate:year+"-"+mes+"-"+dia,
      REndDate:year+"-"+mes+"-"+Rdia
    })
    conectormanager.ListConector(authToken,function(conectors) {
      console.log(conectors)
      thisa.setState({
          conectors,
      isActive:false
        })
        thisa.buscarmes();
    });

    
}
buscarmes(LastEvaluatedKey){
  console.log("LastEvaluatedKey",LastEvaluatedKey)
  var authToken = getToken();
  var thisa= this;
  var cnt_agente_env=0;
  var cnt_agente_err=0;
  var cnt_campaña_env=0;
  var cnt_campaña_err=0;
  var cnt_envio=0;
  var cnt_error=0;
  var cnt_api_envio=0; 
  var cnt_api_error=0;
  this.setState({
    buscando:true,
    msgbusqueda:"Buscando...",
    isActive:true
  })
  var EndDate="";
  var StartDate="";
  var contactos=this.state.contactos
  if(this.state.REndDate!=undefined && this.state.StartDate!=undefined){
    EndDate=new Date(this.state.REndDate+'T23:59:59.000Z')
    StartDate=new Date(this.state.StartDate+'T00:00:00.000Z')
    contactmanager.ObtenerContactosFecha(authToken,EndDate,StartDate,LastEvaluatedKey, function(bcontactos){
      if(bcontactos){
        if(bcontactos.Items==undefined || bcontactos.Items==null){
          console.log("FIN")
          contactos=contactos.concat(bcontactos)
          thisa.setState({
            contactos:contactos
          })
          if(contactos.length>0){
            for(var i=0;i<contactos.length;i++){
              if(contactos[i].estado_mensaje==null || contactos[i].estado_mensaje==undefined){
                contactos[i].estado_mensaje=contactos[i].estado
              }
              if(contactos[i].numero_contacto==null || contactos[i].numero_contacto==undefined){
                contactos[i].numero_contacto=contactos[i].contacto
              }
              if(contactos[i].codigo_error==null || contactos[i].codigo_error==undefined){
                contactos[i].codigo_error=contactos[i].codigo
              }
              if(contactos[i].mensaje_error==null || contactos[i].mensaje_error==undefined){
                contactos[i].mensaje_error=contactos[i].mensaje
              }
              if(contactos[i].detalle_error==null || contactos[i].detalle_error==undefined){
                contactos[i].detalle_error=contactos[i].detalle
              }
              if(contactos[i].medio_envio==null || contactos[i].medio_envio==undefined){
                contactos[i].medio_envio=contactos[i].envio
              }
              if((contactos[i].estado_mensaje=="enviado"||contactos[i].estado=="enviado") && (contactos[i].medio_envio=="campaña" || contactos[i].envio=="campaña")){
                  cnt_envio=cnt_envio+1;
              }else if((contactos[i].estado_mensaje=="error" ||contactos[i].estado=="error") && (contactos[i].medio_envio=="campaña" ||  contactos[i].envio=="campaña" )){
                  cnt_error=cnt_error+1;
              }else if((contactos[i].estado_mensaje=="enviado" || contactos[i].estado=="enviado" ) && (contactos[i].medio_envio=="api" || contactos[i].envio=="api" )){
                  cnt_api_envio=cnt_api_envio+1;
              }else if((contactos[i].estado_mensaje=="error" || contactos[i].estado=="error") && (contactos[i].medio_envio=="api" || contactos[i].envio=="api")){
                cnt_api_error=cnt_api_error+1;
              }
            }
              var conectores_list_name=[]
              var conectores_list_cost=[]
              var conectores_list_value=[]
              var conectores_list_pais=[]
              var conectores_list_price=[]
              thisa.state.conectors.forEach(element => {
              var conect_list=contactos.filter(contact=>contact.conector_id===element.conector_id && contact.estado_mensaje==='enviado')
              var conect_list_a=contactos.filter(contact=>contact.conector_id===element.conector_id && contact.estado==='enviado')
              var pais=""    
              if(element.cod_whatsapp=="593"){
                pais=" (EC)"
              }else if(element.cod_whatsapp=="51"){
                pais=" (PE)"
              }else{
                pais=" (COL)"
               }
              conectores_list_name.push(element.nombre+pais);
              conectores_list_cost.push(element.costo_hsm);
              conectores_list_value.push(conect_list.length+conect_list_a.length);
              conectores_list_price.push((parseFloat(conect_list.length+conect_list_a.length)*parseFloat(element.costo_hsm)).toFixed(3));
              });
            thisa.setState({
                cnt_campañad:contactos.length,
                cnt_campaña_errd:cnt_campaña_err,
                cnt_campaña_envd:cnt_campaña_env,
                checkedId:'',
                visibled:'block',
                visible:'none',
                conectores_list_name,
                conectores_list_price,
                conectores_list_value,
                conectores_list_cost,
                conectores_list_pais,
                title:'',
                contactos,
                cnt_err:cnt_error,
                cnt_env:cnt_envio,
                cnt_api_error:cnt_api_error,
                cnt_api_envio:cnt_api_envio,
                contactos:contactos,
                isActive:false
            })
          }else{
            thisa.setState({
              msgbusqueda:"No se encontro registros en ese rango de fecha",
              isActive:false
            })  
          }
        }else{
          console.log("NOSE")
          contactos=contactos.concat(bcontactos.Items)
          thisa.setState({
            contactos:contactos
          })
          console.log(contactos.LastEvaluatedKey)
          thisa.buscarmes(bcontactos.LastEvaluatedKey)
        }
        
      }else{
        thisa.setState({
          msgbusqueda:"No se encontro registros en ese rango de fecha",
          isActive:false
        })
      }
  })
  contactmanager.ObtenerContactosFechaAgente(authToken,EndDate,StartDate,function(contactos){
    if(contactos){
      for(var i=0;i<contactos.length;i++){
        if(contactos[i].estado_mensaje==null || contactos[i].estado_mensaje==undefined){
          contactos[i].estado_mensaje=contactos[i].estado
        }
        if(contactos[i].numero_contacto==null || contactos[i].numero_contacto==undefined){
          contactos[i].numero_contacto=contactos[i].contacto
        }
        if(contactos[i].codigo_error==null || contactos[i].codigo_error==undefined){
          contactos[i].codigo_error=contactos[i].codigo
        }
        if(contactos[i].mensaje_error==null || contactos[i].mensaje_error==undefined){
          contactos[i].mensaje_error=contactos[i].mensaje
        }
        if(contactos[i].detalle_error==null || contactos[i].detalle_error==undefined){
          contactos[i].detalle_error=contactos[i].detalle
        }
        if(contactos[i].medio_envio==null || contactos[i].medio_envio==undefined){
          contactos[i].medio_envio=contactos[i].envio
        }
      }
        var enviadoa=contactos.filter(contact=>contact.estado_mensaje==='enviado' )
        var enviadoab=contactos.filter(contact=>contact.estado==='enviado' )
        cnt_agente_env=enviadoa.length+enviadoab.length;
        var errora=contactos.filter(contact=>contact.estado_mensaje==='error' )
        var errorab=contactos.filter(contact=>contact.estado==='error' )
        cnt_agente_err=errora.length+errorab.length;
        var conectores_list_name_a=[]
        var conectores_list_cost_a=[]
        var conectores_list_value_a=[]
        var conectores_list_price_a=[]
        thisa.state.conectors.forEach(element => {
        var conect_list=contactos.filter(contact=>contact.conector_id===element.conector_id && contact.estado_mensaje==='enviado')
        var conect_list_a=contactos.filter(contact=>contact.conector_id===element.conector_id && contact.estado==='enviado')
            
        conectores_list_name_a.push(element.nombre);
        conectores_list_cost_a.push(element.costo_hsm);
        conectores_list_value_a.push(conect_list.length+conect_list_a.length);
        conectores_list_price_a.push((parseFloat(conect_list.length+conect_list_a.length)*parseFloat(element.costo_hsm)).toFixed(3));
        });
       thisa.setState({
           cnt_agented:contactos.length,
           cnt_agente_envd:cnt_agente_env,
           cnt_agente_errd:cnt_agente_err,
           checkedId:'',
           visibled:'block',
           visible:'none',
           conectores_list_name_a,
           conectores_list_price_a,
           conectores_list_value_a,
           conectores_list_cost_a,
           title:'',
           agentes:contactos,
           isActive:false

       })
    }else{
      thisa.setState({
        isActive:false
      })
    }
})
  }else{
    this.setState({
      msgbusqueda:"Campos vacios al asignar rango de busqueda",
      isActive:false
    })
  }
  
}
handleSearch(e,lastkey){
  var contactos=this.state.contactos
  if(lastkey==undefined){
    this.setState({
      contactos:[]
    })
    contactos=[]
    console.log(contactos)
  }
    e.preventDefault();
    var authToken = getToken();
    var thisa= this;
    var cnt_agente_env=0;
    var cnt_agente_err=0;
    var cnt_campaña_env=0;
    var cnt_campaña_err=0;
    var cnt_envio=0;
    var cnt_error=0;
    var cnt_api_envio=0;
    var cnt_api_error=0;
    this.setState({
      buscando:true,
      msgbusqueda:"Buscando...",
      isActive:true
    })
    if(this.state.EndDate!=undefined && this.state.StartDate!=undefined){
      var EndDate=new Date(this.state.EndDate+'T23:59:59.000Z')
      var StartDate=new Date(this.state.StartDate+'T00:00:00.000Z')
      contactmanager.ObtenerContactosFecha(authToken,EndDate,StartDate,lastkey,function(bcontactos){
        if(bcontactos){
          if(bcontactos.Items==undefined || bcontactos.Items==null){
            console.log("FIN")
            contactos=contactos.concat(bcontactos)
            
            console.log("NOSE")
            console.log(bcontactos.length)
            console.log(contactos.length)

            thisa.setState({
              contactos:contactos
            })
            if(contactos.length>0){
              for(var i=0;i<contactos.length;i++){
                if(contactos[i].estado_mensaje==null || contactos[i].estado_mensaje==undefined){
                  contactos[i].estado_mensaje=contactos[i].estado
                }
                if(contactos[i].numero_contacto==null || contactos[i].numero_contacto==undefined){
                  contactos[i].numero_contacto=contactos[i].contacto
                }
                if(contactos[i].codigo_error==null || contactos[i].codigo_error==undefined){
                  contactos[i].codigo_error=contactos[i].codigo
                }
                if(contactos[i].mensaje_error==null || contactos[i].mensaje_error==undefined){
                  contactos[i].mensaje_error=contactos[i].mensaje
                }
                if(contactos[i].detalle_error==null || contactos[i].detalle_error==undefined){
                  contactos[i].detalle_error=contactos[i].detalle
                }
                
              if(contactos[i].medio_envio==null || contactos[i].medio_envio==undefined){
                contactos[i].medio_envio=contactos[i].envio
              }
                if((contactos[i].estado_mensaje=="enviado" || contactos[i].estado=="enviado" )&& (contactos[i].medio_envio=="campaña"|| contactos[i].envio=="campaña")){
                    cnt_envio=cnt_envio+1;
                }else if((contactos[i].estado_mensaje=="error" || contactos[i].estado=="error" ) && (contactos[i].medio_envio=="campaña" || contactos[i].envio=="campaña")){
                    cnt_error=cnt_error+1;
                }else if((contactos[i].estado_mensaje=="enviado" || contactos[i].estado=="enviado") && (contactos[i].medio_envio=="api" || contactos[i].envio=="api")){
                    cnt_api_envio=cnt_api_envio+1;
                }else if((contactos[i].estado_mensaje=="error" || contactos[i].estado=="error") && (contactos[i].medio_envio=="api" || contactos[i].envio=="api")){
                  cnt_api_error=cnt_api_error+1;
                }
  
              }
                var conectores_list_name=[]
                var conectores_list_cost=[]
                var conectores_list_value=[]
                var conectores_list_pais=[]
                var conectores_list_price=[]
                thisa.state.conectors.forEach(element => {
                var conect_list=contactos.filter(contact=>contact.conector_id===element.conector_id && (contact.estado_mensaje==='enviado' || contact.estado==='enviado'))
                var pais=""    
                if(element.cod_whatsapp=="593"){
                  pais=" (EC)"
                }else if(element.cod_whatsapp=="51"){
                  pais=" (PE)"
                }else{
                  pais=" (COL)"
                 }
                conectores_list_name.push(element.nombre+pais);
                conectores_list_cost.push(element.costo_hsm);
                conectores_list_value.push(conect_list.length);
                conectores_list_price.push((parseFloat(conect_list.length)*parseFloat(element.costo_hsm)).toFixed(3));
                });
              thisa.setState({
                  cnt_campañad:contactos.length,
                  cnt_campaña_errd:cnt_campaña_err,
                  cnt_campaña_envd:cnt_campaña_env,
                  checkedId:'',
                  visibled:'block',
                  visible:'none',
                  conectores_list_name,
                  conectores_list_price,
                  conectores_list_value,
                  conectores_list_cost,
                  conectores_list_pais,
                  title:'',
                  contactos,
                  cnt_err:cnt_error,
                  cnt_env:cnt_envio,
                  cnt_api_error:cnt_api_error,
                  cnt_api_envio:cnt_api_envio,
                  contactos:contactos,
                  isActive:false
              })
            }else{
              thisa.setState({
                msgbusqueda:"No se encontro registros en ese rango de fecha",
                isActive:false
              })  
            }
          }else{
            console.log("NOSE")

            contactos=contactos.concat(bcontactos.Items)
            thisa.setState({
              contactos:contactos
            })
            console.log(bcontactos.Items.length)
            console.log(contactos.length)
            console.log(thisa.state.contactos.length)
            console.log(contactos.LastEvaluatedKey)
            thisa.handleSearch(e,bcontactos.LastEvaluatedKey)
            }
        }else{
          thisa.setState({
            msgbusqueda:"No se encontro registros en ese rango de fecha",
             isActive:false
           })
        }
    })
    contactmanager.ObtenerContactosFechaAgente(authToken,EndDate,StartDate,function(contactos){
        if(contactos){
          for(var i=0;i<contactos.length;i++){
            if(contactos[i].estado_mensaje==null || contactos[i].estado_mensaje==undefined){
              contactos[i].estado_mensaje=contactos[i].estado
            }
            if(contactos[i].numero_contacto==null || contactos[i].numero_contacto==undefined){
              contactos[i].numero_contacto=contactos[i].contacto
            }
            if(contactos[i].codigo_error==null || contactos[i].codigo_error==undefined){
              contactos[i].codigo_error=contactos[i].codigo
            }
            if(contactos[i].mensaje_error==null || contactos[i].mensaje_error==undefined){
              contactos[i].mensaje_error=contactos[i].mensaje
            }
            if(contactos[i].detalle_error==null || contactos[i].detalle_error==undefined){
              contactos[i].detalle_error=contactos[i].detalle
            }
            
          if(contactos[i].medio_envio==null || contactos[i].medio_envio==undefined){
            contactos[i].medio_envio=contactos[i].envio
          }
          }
          var enviadoa=contactos.filter(contact=>contact.estado_mensaje==='enviado' )
          var enviadoas=contactos.filter(contact=>contact.estado==='enviado' )
          cnt_agente_env=enviadoa.length+enviadoas.length;
          var errora=contactos.filter(contact=>contact.estado==='error' )
          var erroras=contactos.filter(contact=>contact.estado_mensaje==='error' )
          cnt_agente_err=errora.length+erroras.length;
          var conectores_list_name_a=[]
          var conectores_list_cost_a=[]
          var conectores_list_value_a=[]
          var conectores_list_price_a=[]

          thisa.state.conectors.forEach(element => {
            var conect_list=contactos.filter(contact=>contact.conector_id===element.conector_id && (contact.estado_mensaje==='enviado' || contact.estado==='enviado'))
                
            conectores_list_name_a.push(element.nombre);
            conectores_list_cost_a.push(element.costo_hsm);
            conectores_list_value_a.push(conect_list.length);
            conectores_list_price_a.push((parseFloat(conect_list.length)*parseFloat(element.costo_hsm)).toFixed(3));
            });
            
          thisa.setState({
              cnt_agented:contactos.length,
              cnt_agente_envd:cnt_agente_env,
              cnt_agente_errd:cnt_agente_err,
              checkedId:'',
              visibled:'block',
              visible:'none',
              conectores_list_name_a,
              conectores_list_price_a,
              conectores_list_value_a,
              conectores_list_cost_a,
              title:'',
              agentes:contactos,
              isActive:false

          })
        }
    })
      }else{
        this.setState({
          msgbusqueda:"Campos vacios al asignar rango de busqueda",
          isActive:false
        })
      }
      
  }
  handleExport(event){
      event.preventDefault();

      var items=this.state.contactos;
      var agents=this.state.agentes;
      for(let i=0;i<agents.length;i++){
        items.push(agents[i])
      }
      var filename= uuidv4();
      var headers={
        user_id:'Id',
        numero_contacto:'Contacto',
        medio_envio:'TipoEnvio',
        fecha_hora:'FechaInteraccion',
        conector_name:'Conector',
        template_name:'Template',
        estado_mensaje:"Estado",
        codigo_error:"Codigo",
        mensaje_error:"Mensaje",
        detalle_error:"Detalle",
      }
    var agentes=false;
    var campana=false;
      if(agents.length>0){
        headers.agent_id="AgenteId"
        headers.agente="Agente"
        agentes=true
      }
      if(items.length>0){
        headers.usuario="Usuario"
        campana=true
      }
      const jsonObject = JSON.stringify(items);
    const csv = this.convertToCSV(jsonObject,headers,agentes,campana);
      const exportName = filename + ".csv" || "export.csv";
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, exportName);
      } else {
          console.log("AQUI2")
          const link = document.createElement("a");
          if (link.download !== undefined) {
              const url = URL.createObjectURL(blob);
              link.setAttribute("href", url);
              link.setAttribute("download", exportName);
              link.style.visibility = "hidden";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
          }
    }
}
convertToCSV(objArray,headers,agentes,campana) {
  const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  let str = "Id,Contacto,TipoEnvio,FechaEnvio,Conector,Template,EstadoEnvio,CodigoError,MensajeError,DetalleError"
  if(agentes){
    str=str+",AgenteId,Agente"
  }
  if(campana){
    str=str+",Usuario"
  }
  str=str+ "\r\n";
   for (let i = 0; i < array.length; i++) {
        let line = "";
        for (let index in headers) {
            if (line != ""){
                line += ",";
            }
            var text =array[i][index];
            var tipo=array[i]["medio_envio"]
            if(headers[index]=="FechaEnvio"){
              text= (new Date(text)).toLocaleString()
            }
            if(headers[index]=="Usuario"){
              if(tipo=="campaña"){
                text=array[i]["agente"]
              }
            }
            if(headers[index]=="Agente"){
              if(tipo=="campaña"){
                text="-"
              }
            }
            if(text===undefined){
              text="-"
          }else if(text===""){
              text="-"
          }
            line += text;
        }
        str += line + "\r\n";
    }
    
    return str;
   }
    render() {
      var values=[]
      var prices=[]
      for( let i=0;i<this.state.conectores_list_value.length;i++){
        values.push(parseInt(this.state.conectores_list_value[i])+parseInt(this.state.conectores_list_value_a[i]))
        prices.push(parseFloat(this.state.conectores_list_price[i])+parseFloat(this.state.conectores_list_price_a[i]))
      }
          const genPieDatad=()=>{
            return {
              datasets: [
                {
                  data: [parseInt(this.state.cnt_env)+parseInt(this.state.cnt_agente_envd)+parseInt(this.state.cnt_api_envio),parseInt(this.state.cnt_err)+parseInt(this.state.cnt_agente_errd)+parseInt(this.state.cnt_api_error)],
                  backgroundColor: [
                    '#77dd77',
                    '#ff6961',
                  ],
                  label: 'Campaña',
                },
              ],
              labels: ['Enviados', 'Error'],
            };
          };
          const genPieDatasd=()=>{
            return {
              datasets: [
                {
                  data: [this.state.cnt_agented,parseInt(this.state.cnt_env)+parseInt(this.state.cnt_err),parseInt(this.state.cnt_api_envio)+parseInt(this.state.cnt_api_error)],
                  backgroundColor: [
                    '#fdfd69',
                    '#77dd77',
                    '#80CEE1'
                  ],
                  label: 'Campaña',
                },
              ],
              labels: ['Agentes', 'Campaña','API'],
            };
          };

          const genPieDatacamd=()=>{
            return {
              datasets: [
                {
                  data: [this.state.cnt_env,this.state.cnt_err],
                  backgroundColor: [
                    '#77dd77',
                    '#ff6961',
                  ],
                  label: 'Campaña',
                },
              ],
              labels: ['Enviados', 'Error'],
            };
          };
          const genPieDatasagend=()=>{
            return {
              datasets: [
                {
                  data: [this.state.cnt_agente_envd,this.state.cnt_agente_errd],
                  backgroundColor: [
                    '#77dd77',
                    '#ff6961',
                  ],
                  label: 'Campaña',
                },
              ],
              labels: ['Enviados', 'Error'],
            };
          };

const genLineData = (moreData = {}, moreData2 = {}) => {
  return {
    labels: this.state.conectores_list_name,
    datasets: [
      {
        label: 'Costo',
        backgroundColor: '#77dd77',
        borderColor: '#77dd77',
        borderWidth: 1,
        data: prices,
        ...moreData2,
      },
    ],
  };
};
const genLineDatas = () => {
    return {
        datasets: [
          {
            data: values,
            backgroundColor: [
              '#77dd77',
              '#84b6f4',
              '#ff6961',
              '#fdcae1'
            ],
            label: 'Campaña',
          },
        ],
        labels: this.state.conectores_list_name,
      };
  };
  const genPieDatasapi=()=>{
    return {
      datasets: [
        {
          data: [this.state.cnt_api_envio,this.state.cnt_api_error],
          backgroundColor: [
            '#77dd77',
            '#ff6961',
          ],
          label: 'API',
        },
      ],
      labels: ['Enviados', 'Error'],
    };
  };
  var isActive=this.state.isActive;
        return (
        <>       
         <LoadingOverlay
            active={isActive}
            spinner
            styles={{
              wrapper: {
                width: '100%',
                height: '100%',
                overflow: isActive ? 'hidden' : '',
              },
            }}
            text="Cargando ..."
          >
        <div>
            <br/>
            <h2>Reportes por fecha</h2>
            <br/>
            <div className="row" style={{maxWidth:"800px", marginLeft:"20px"}}>
                <div className="col-sm-4" >
                    <label style={{textAlign:"end"}}>Fecha inicial</label>
                    <input 
                        onChange={(e)=>this.setState({
                            StartDate: e.target.value
                        })}
                    type="date" className="form-control"></input>
                </div>
                <div className="col-sm-4" >
                    <label style={{textAlign:"end"}}>Fecha final</label>
                    <input 
                        onChange={(e)=>this.setState({
                            EndDate: e.target.value
                        })}
                    type="date" className="form-control"></input>
                </div>   
                <div className="col-sm-4">
                <br/>
                <button className="paddingbutton btn btn-primary btn-block"
                onClick={this.handleSearch}> Buscar</button>
            </div>            
            </div>
            <br/>
           { this.state.contactos.length!=0?(<>
            <h3>Reporte desde: {this.state.StartDate} hasta: {this.state.EndDate}</h3>
            <div style={{display:(this.state.visibled)}} >
            <h2>{this.state.title}</h2>
            <br/>
            <div className="row">
                <div className="col-sm-3">
                <button  className="paddingbutton btn btn-primary btn-block" 
                onClick={this.handleExport}>Exportar Lista de contactos</button>
                </div>
            </div>
            <br/>
                <Row>
                <Col xl={4} lg={4} md={4}>
                <Card>
                    <CardHeader>Conectores</CardHeader>
                    <CardBody>
                    <Doughnut data={genLineDatas()} />
                    </CardBody>
                </Card>
                </Col>
                <Col xl={6} lg={6} md={6}>
                <Card>
                    <CardHeader>Conectores</CardHeader>
                    <CardBody>
                    <Bar data={genLineData()} />
                    </CardBody>
                </Card>
                </Col>
            </Row>
            <Row>
                    <Col xl={3} lg={4} md={4}>
                    <Card>
                        <CardHeader>Mensajes Enviados</CardHeader>
                        <CardBody>
                        <Pie data={genPieDatad} />
                        </CardBody>
                    </Card>
                    </Col>

                    <Col xl={3} lg={4} md={4}>
                    <Card>
                        <CardHeader>Medio de Envío</CardHeader>
                        <CardBody>
                        <Pie data={genPieDatasd} />
                        </CardBody>
                    </Card>
                    </Col>
                    <Col xl={3} lg={4} md={4}>
                    <Card>
                        <CardHeader>Mensajes enviados por campaña</CardHeader>
                        <CardBody>
                        <Pie data={genPieDatacamd} />
                        </CardBody>
                    </Card>
                    </Col>

                    <Col xl={3} lg={4} md={4}>
                    <Card>
                        <CardHeader>Mensajes enviados por agentes</CardHeader>
                        <CardBody>
                        <Pie data={genPieDatasagend} />
                        </CardBody>
                    </Card>
                    </Col>
                    <Col xl={3} lg={4} md={4}>
                      <Card>
                          <CardHeader>Mensajes Enviados por api</CardHeader>
                          <CardBody>
                          <Pie data={genPieDatasapi} />
                          </CardBody>
                      </Card>
                    </Col>
                </Row>
                <br/>
                </div>       
       </>):(<>
       {
         this.state.buscando?(
            <label>{this.state.msgbusqueda}. Desde: {this.state.StartDate} hasta: {this.state.EndDate}</label>
         ):(<></>)
       }
       </>)}

          </div>
          </LoadingOverlay>
      
         </>
        );
    }
}